<template>
  <div style="padding: 0 5vw">
<!--    云桌面-->
    <el-row class="currFirTit">云桌面</el-row>
    <el-row class="currSecTit">产品概述</el-row>
    <el-row class="suojin"><p>云计算技术的发展正在改变着社会生产和人们的生活，面向云桌面及应用发布解决方案纷芸而出，满怀科技创新历史使命的众多高科技企业通过自己的努力为这个时代作出了卓越贡献，云上应用正在改变传统，按需交付即将主导未来。</p></el-row>
    <el-row class="suojin"><p>NVDI是领航动力信息系统有限公司自主研发、拥有自主知识产权的桌面及应用虚拟化产品，通过统一管理平台（控制引擎）将虚拟化的桌面组件（包括应用软件、操作系统和用户数据等）转移在云数据中心进行托管，并进行统一管理、集中控制。        NVDI可将桌面和应用转变为一种按需服务，向任何地点、使用任何设备的任何用户交付，不仅可以安全地向PC、平板设备、笔记本电脑和云终端交付云桌面或应用，而且可以提供高清、流畅的用户体验，实现媲美本地PC及图形工作站的体验效果，在改变传统分布式桌面及设计应用单站点运行的现有模式下，既可以单独发布应用，又可以发布整个桌面系统，支持多种接入平台，有效降低运维成本，提高业务安全和数据安全，重新定义云管模型，重塑工作方式，为用户打造更加灵活的应用交付模式和办公空间。</p></el-row>
    <img src="@/assets/panshProduct/cloudDesktop/moyunzhuomian.png">

    <el-row class="hrLine">
      <img src="@/assets/mobile/moPanshProduct/hrLine.png">
    </el-row>

<!--    产品架构-->
    <el-row class="currFirTit">产品架构</el-row>
    <el-row v-for="(item,index) in proFramework" :key="'moJiaGou'+index" class="frameworkList">
      <el-row class="currSecTit">{{item.title}}</el-row>
      <el-row><img :src="item.imgSrc"></el-row>
    </el-row>

    <el-row class="hrLine">
      <img src="@/assets/mobile/moPanshProduct/hrLine.png">
    </el-row>

<!--    产品特点-->
    <el-row class="currFirTit">产品特点</el-row>
    <el-row>
      <img src="@/assets/panshProduct/cloudDesktop/motedian.png" width="100%">
    </el-row>

    <el-row class="hrLine">
      <img src="@/assets/mobile/moPanshProduct/hrLine.png">
    </el-row>

<!--    产品功能-->
    <el-row class="currFirTit">产品功能</el-row>
    <el-row v-for="(item,index) in proFunction" :key="'moGongNeng'+index">
      <el-row class="currSecTit">{{item.title}}</el-row>
      <el-row class="proFunctionDesc suojin"><p>{{item.desc}}</p></el-row>
      <el-row class="proFunctionImg"><img :src="item.imgSrc" width="90%"></el-row>
    </el-row>

    <el-row class="hrLine">
      <img src="@/assets/mobile/moPanshProduct/hrLine.png">
    </el-row>

<!--    应用场景-->
    <el-row class="currFirTit">应用场景</el-row>
    <el-row>
      <ul style="display: flex;padding:0 2%">
        <li v-for="(item,index) in yingyongcj" :key="'moChangJing'+index">
          <img :src="item.imgSrc" width="100%">
        </li>
      </ul>
    </el-row>

  </div>
</template>

<script>
export default {
  name: "moCloudDesktop",
  data(){
    return{
      proFramework:[{title:'逻辑架构',imgSrc:require('@/assets/panshProduct/cloudDesktop/luoji.png'),},
        {title:'核心组件',imgSrc:require('@/assets/panshProduct/cloudDesktop/zujian.png'),},
        {title:'系统架构',imgSrc:require('@/assets/panshProduct/cloudDesktop/jiagou.png'),},
        {title:'系统架构说明',imgSrc:require('@/assets/panshProduct/cloudDesktop/shuoming.png'),}],
      proFunction:[
        {title:'身份认证',desc:'多功能身份认证服务，通过多元安全认证、磐石盾、量子密钥等多种身份认证方式，保证用户登录安全，将用户数据集中统一存储于后台云桌面服务器中，统一备份统一管理，保障数据的永不丢失。',imgSrc:require('@/assets/panshProduct/cloudDesktop/gongneng01.png'),},
        {title:'资源管理',desc:'通过集中化管理功能，全面管理虚拟化后台资源，包括资源池、服务器、虚拟机、存储和网络，实时展示资源的运行状态，对整个 虚拟化环境进行组织、监控和配置，从而降低管理成本。',imgSrc:require('@/assets/panshProduct/cloudDesktop/gongneng02.png'),},
        {title:'交付管理',desc:'通过交付管理，管理员可以对虚拟桌面和应用进行统一管理、集中控制。对于桌面池中的虚拟桌面可以进行批量部署，对本地用户、域用户、用户组进行批量授权以及分组维护，包括增加、修改、删除、查询等操作，并且可以查看用户及用户分组所授权的虚拟桌面信息。',imgSrc:require('@/assets/panshProduct/cloudDesktop/gongneng03.png'),},
        {title:'运维监控',desc:'统一的Web管理控制引擎，实现了对物理及虚拟资源的集中管理、统一监控；全状态监控系统，从底层资源利用、会话接入性能和外设通道等多个层面监控云桌面平台的使用性能情况。',imgSrc:require('@/assets/panshProduct/cloudDesktop/gongneng04.png'),},
      ],
      yingyongcj:[
        {imgSrc: require('@/assets/panshProduct/cloudDesktop/dx1.png')},
        {imgSrc: require('@/assets/panshProduct/cloudDesktop/dx2.png')},
        {imgSrc: require('@/assets/panshProduct/cloudDesktop/dx3.png')},
        {imgSrc: require('@/assets/panshProduct/cloudDesktop/dx4.png')},
        {imgSrc: require('@/assets/panshProduct/cloudDesktop/dx5.png')},
        {imgSrc: require('@/assets/panshProduct/cloudDesktop/dx6.png')}
      ]
    }
  }
}
</script>

<style scoped>
/*重置样式*/
li{
  list-style: none;
}
a{
  text-decoration: none;
}
*{
  font-size: 3vw;
}
img{
  width: 100%;
}
/*
  1、清除重置样式  字体大小
  2、磐石动态  标题和详情的   单行显示

*/
.currFirTit{
  font-size: 5vw;
  text-align: center;
  margin: 3vw 0;
}
.currSecTit{
  font-size: 4.25vw;
  margin-bottom: 3vw;
}
.hrLine{
  margin: 3vw auto;
  width: 80%;
}
.suojin{
  text-indent: 2em;
  line-height: 5.5vw;
}
/*产品特点*/

</style>