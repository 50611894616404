<template>
  <div>
    <!--    logo-->
    <el-row class="topLogo">
      <el-col :span="23"><img src="@/assets/layout/logo.png" style="width: 25vw" ></el-col>
      <el-col :span="1"><i class="iconfont icon-gengduo" @click="openRightDrawer"></i></el-col>
    </el-row>

    <el-tabs v-model="activeName" >
      <el-tab-pane label="磐石云桌面" name="yunzhuomian">
        <moCloudDesktop></moCloudDesktop>
      </el-tab-pane>
      <el-tab-pane label="磐石云终端" name="yunzhongduan">磐石云终端</el-tab-pane>
      <el-tab-pane label="磐石一体机" name="yitiji">磐石一体机</el-tab-pane>
      <el-tab-pane label="安全网关" name="wangguan">安全网关</el-tab-pane>
      <el-tab-pane label="运维平台" name="pingtai">运维平台</el-tab-pane>
    </el-tabs>

<!--    右侧弹窗组件-->
  <rightDrawer ref="son"></rightDrawer>
  </div>
</template>

<script>
import moCloudDesktop from "@/views/Moperation/rightBranch/product/moCloudDesktop.vue";
import rightDrawer from "@/components/rightDrawer";
export default {
  name: "moPanshProduct",
  components:{moCloudDesktop,rightDrawer},
  data(){
    return{
      activeName: 'yunzhuomian',
    }
  },
  methods:{
    openRightDrawer(){
      this.$refs.son.showRightDrawer()
    }
  }
}
</script>

<style scoped>
/*重置样式*/
li{
  list-style: none;
}
a{
  text-decoration: none;
}
*{
  font-size: 3vw;
}
img{
  width: 100%;
}
/*
  1、清除重置样式  字体大小
  2、磐石动态  标题和详情的   单行显示

*/
/*页面样式*/
/*顶部logo*/
.topLogo{
  display: flex;
  align-items: center;
  padding: 3vw 4vw;
  /*border-bottom: 0.3vw solid #101010;*/
}
.icon-gengduo{
  font-size: 4vw;
  position: fixed;
  top: 1.5%;
  right: 5%;
  z-index: 2;
  background-color: #0c3056;
  padding: 1%;
}
/*顶部 右侧弹窗*/
.panshDrawer{
  padding: 0 4vw;
}
.panshDrawer li{
  margin: 8vw 0;
}
.panshDrawer li a{
  font-size: 4.25vw;
}
>>> .el-drawer__header{
  font-size: 4.5vw;
  background-color: #09283d;
  margin-bottom: 0;
  padding-bottom: 20px;
}
>>> .el-drawer__body{
  background-color: #09283d;
}
>>> .el-tabs__item{
  font-size: 4vw;
}
</style>